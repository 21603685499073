.laboratory-clearance-request-table {
  .tableUI {
    .table {
      thead {
        tr {
          th {
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 10%;
            }
          }
        }
      }
    }
  }
}