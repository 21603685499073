.certificate-fedal-death {
  .black-border-container {
    border: solid 4px #000;
    padding: 0;

    .black-row.last-row {
      border-bottom: none;

      h2 {
        font-weight: bold;
        font-size: 1.3rem;
      }
    }

    .black-row {
      border-bottom: solid 4px #000;

      .block-container {
        padding: 1rem;

        .inline-group {
          gap: 4rem;

          h4 {
            margin: 0 !important;
            display: flex;
            align-items: center;
          }
        }

        h6 {
          margin: 0;
          font-weight: bold;
        }

        .boxes-cont {
          display: flex;
          align-items: flex-start;
          gap: 1rem;

          .boxes {
            p {
              font-size: 1rem;
              margin: 0;
              font-weight: bold;
            }

            .boxes-count {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .box {
                display: block;
                width: 2rem;
                height: 2rem;
                border: dashed 1px #707070;
              }
            }
          }
        }

        &.marriage {
          h3 {
            font-weight: bold;
            line-height: 1;
            font-size: 1.3rem;
            margin: 0;
          }
        }

        &.medical-certificate {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          h2 {
            font-weight: bold;
            line-height: 1;
            font-size: 1.3rem;
            margin: 0;
          }

          p {
            font-size: 1rem;
            font-weight: bold;
            margin: 0;
            line-height: 1;
          }
        }

        &.affidavit {
          h2 {
            font-size: 2rem;
            text-align: center;
            margin: 0 0 1rem;
          }

          p {
            font-size: 1rem;
            font-weight: bold;
            margin: 0 0 1rem;
            line-height: 1;

            .inline-group-check {
              position: relative;
              display: inline-block;
              bottom: -3px;
            }

            .form-check {
              display: inline-flex;
              align-items: flex-end;
              gap: 5px;
              padding: 0;

              label {
                font-size: 1rem;
              }

              input {
                width: 19px;
                height: 19px;
                font-size: 1rem;
                float: unset;
                margin: 0;
                border-radius: 3px;
              }
            }

            input.non-editable,
            input.editable {
              border-radius: 7px !important;
              font-size: 1rem;
              height: 33px;

              &::placeholder {
                font-weight: normal;
              }
            }
          }

          .attended {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            .form-check {
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 0;

              input {
                width: 25px !important;
                height: 25px !important;
                float: unset;
                margin: 0;
                border-radius: 7px;
              }

              label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }
            }

            .inline-group {
              input {
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;
              }
            }
          }
        }

        .blue {
          color: #00a8ff !important;
        }

        h4 {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
        }

        .p-header {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 12px;
        }

        .certify {
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          text-indent: 3rem;

          .form-check {
            display: inline-flex;
            align-items: center;
            gap: 5px;
            padding: 0;

            input {
              width: 18px;
              height: 18px;
              float: unset;
              margin: 0;
              border-radius: 3px;
            }

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }
          }
        }

        .signature {
          position: relative;
          max-width: 340px;
          margin-left: auto;

          p.over-printed-name {
            position: absolute;
            font-size: 13px !important;
            font-weight: bold;
            bottom: -2rem;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto;
          }

          input {
            font-size: 13px !important;
            width: 100% !important;

            &::placeholder {
              font-weight: normal;
            }
          }
        }

        input.non-editable,
        input.editable {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;

          &::placeholder {
            font-weight: normal;
          }
        }
      }

      .block-container.small {
        padding: 5px;
      }

      .block-container.small-border {
        border: solid 1px #707070;
        margin: 1rem;
        border-radius: 7px;
      }

      .block-container.bordered-top-right {
        border-top: solid 4px #000;
        border-right: solid 4px #000;
      }

      .main-content {
        width: 100%;
      }

      .right-border {
        border-right: solid 4px #000;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        padding: 1rem;

        .center-header {
          text-align: center;

          p {
            margin: 0;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.6;
          }

          h2 {
            font-weight: bold;
            font-size: 1.5rem;
            margin: 5px 0 0;
          }
        }

        .left-header,
        .right-header {
          margin: 0;
          line-height: 1.4;
          align-self: flex-start;
          font-size: 10px;

          span {
            display: block;
          }
        }
      }

      .inline-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .input-group {
          align-items: center;
        }
      }

      .selections {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;

        .form-check {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 0;

          input {
            width: 25px;
            height: 25px;
            float: unset;
            margin: 0;
            border-radius: 7px;
          }

          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }
        }
      }

      .selections.inline {
        flex-direction: row;
      }

      .checkbox-textbox {
        gap: 10px;

        input {
          border-radius: 7px !important;
          font-size: 13px;
          height: 25px;

          &::placeholder {
            font-weight: normal;
          }
        }
      }

      .input-group {
        flex-direction: column;
        display: flex;
        gap: 10px;

        label {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
          flex: 0 0 auto;
        }

        input {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;

          &::placeholder {
            font-weight: normal;
          }
        }

        textarea {
          font-size: 13px;
          font-weight: bold;
          border-radius: 7px !important;
        }

        input,
        textarea {
          width: 100%;
          border-radius: 7px;

          &::placeholder {
            font-size: 13px;
            font-weight: normal;
          }
        }

        .selections {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;

          .form-check {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0;

            input {
              width: 25px;
              height: 25px;
              float: unset;
              margin: 0;
            }

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }
          }
        }

        .selections.inline-selection {
          flex-direction: row;
        }

        .gender {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;
          width: 100%;
        }
      }

      .inline-label {
        flex-direction: row !important;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
      }

      .no-border {
        input {
          border-radius: 0 !important;
          border: none;
          border-bottom: solid 1px #000;
        }
      }

      .block-with-sidebar {
        display: flex;

        .side-header {
          flex: 0 0 2rem;
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            font-size: 0.9rem;
            font-weight: bold;
            writing-mode: vertical-rl;
            text-orientation: upright;
            margin: 0;
          }
        }
      }
    }

    .black-row.remarks-row {
      height: 10rem;

      h2 {
        font-weight: bold;
        font-size: 1.3rem;
      }
    }
  }
}
