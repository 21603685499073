@media print {
  @page {
    margin: 1.5cm 1cm;
  }
  .print_body { 
    header {
      display: flex;
      align-items: center;
      flex-direction: column;

      .header_logo {
          margin: 15px auto;
          img {
              text-align: center;
              width: 170px;
              height: auto;
          }
      }

      .header_title {
        font-weight: bold;
        font-size: 16px;
        color: black !important;
      }

      .header_subtitle {
        margin-top: 5px;
        font-weight: bold;
        font-size: 14px;
        color: black !important;
      }
      
    }
    .input-group {
      flex-direction: column;
      display: flex;
      gap: 10px;

      label {
        margin: 0;
        font-weight: bold;
        font-size: 13px;
      }

      p {
        font-size: 13px !important;
        width: 100% !important;
      }
    }
    
    .inline-label {
      flex-direction: row !important;
      flex-wrap: nowrap;
      align-items: center !important;
      justify-content: flex-end;
    }

    .textarea-table-td {
      font-size: 13px;
      font-weight: bold; 
    }    
    
    .signature {
      position: relative;
      min-width: 200px;
      .name {
        font-size: 13px !important;
        width: 100% !important;
        margin-top: auto;
        margin-bottom: auto;
      }
      p.over-printed-name {
        position: absolute;
        font-size: 13px !important;
        font-weight: bold;
        bottom: -2rem;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
      }
    }
  }
}


