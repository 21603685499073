@media print {
    @page {
        margin: 1.5cm 1cm;
    }

    .print_body {
        // padding: 20px;
      
        header {
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;

            .header_logo {
                margin: 15px auto;
                img {
                    text-align: center;
                    width: 170px;
                    height: auto;
                }
            }

            .header_title {
              font-weight: bold;
              font-size: 16px;
              color: black !important;
             }

            .header_subtitle {
              margin-top: 5px;
              font-weight: bold;
              font-size: 14px;
              color: black !important;
            }
            
        }

        table {
            font-size: 12px;
            page-break-inside: auto;
            border: 1px solid #dee2e6;
    
            /* thead {
                background-color: #00a8ff;
                border: 1px solid #00a8ff;
            } */
            thead > tr > th {
                // border-right: 1px solid #dee2e6;
                // display: none !important;
                // visibility: hidden !important;
                text-align: center;
                font-weight: bold;
                color: black;
                vertical-align: middle;
            }
    
            tbody > tr > td {
                // border-right: 1px solid #dee2e6;
                font-weight: 500;
            }
        }

        .first-row {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            align-items: center;
    
            .input-group {
              width: 50%;
              max-width: 400px;
              display: flex;
              flex-direction: column;
              text-align: center;
              gap: 0px;
    
              p {
                font-size: 1rem;
                margin: 0px;
              }
    
              label {
                font-weight: bold;
              }
            }
          }
    
          .second-row {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            align-items: flex-end;
            .input-group {  
              width: 35%;
              max-width: 400px;
              display: flex;
              justify-content: space-between;
              text-align: center;
            
              p {
                margin: 0px;
                text-align: center;
                font-size: 1rem;
              }
              label {
                font-weight: bold;
                margin: 0;
                display: flex;
                align-items: center;
              }
            }
          }
    
          .third-row {
            display: flex;
            align-items: center;
            justify-content: center;
    
            h2 {
              margin: 0;
              font-weight: bold;
            }
          }

          .signature {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
    
            .attending-physician {
              display: flex;
              flex-direction: column;
              text-align: center;
              width: 35%;
              max-width: 400px;
              p {
                border: none;
                border-bottom: solid 2px #000;
                border-radius: 0;
                padding-bottom: 10px;
              }
    
              label {
                font-weight: bold;
                font-size: 1.2rem;
              }
            }
          }
    
          p.seal {
            border-bottom: solid 1px grey;
          }

          .row-inline {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            
            .section {
              display: flex;
              flex-direction: row;

              label {
                font-weight: bold;
                font-size: 12px;
                padding-right: 10px;
              }

              p {
                font-size: 14px;
              }
              padding-right: 80px;
            }
          }
    }
}