.admission-and-discharge {
  .main-box-container {
    border: solid 1px #707070;
    border-radius: 5px;

    .box-cont {
      border-bottom: solid 1px #707070;
      padding: 1rem;

      h3 {
        margin: 0 0 10px;
        padding: 0;
        font-weight: bold;
        font-size: 16px;
      }

      .input-group {
        flex-direction: column;
        display: flex;
        gap: 10px;

        label {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
        }

        input {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;

          &::placeholder {
            font-weight: normal;
          }
        }

        textarea {
          font-size: 13px;
          font-weight: bold;
          border-radius: 7px !important;
        }

        input,
        textarea {
          width: 100%;
          border-radius: 7px;

          &::placeholder {
            font-size: 13px;
            font-weight: normal;
          }
        }

        .selections {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;

          .form-check {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0;

            input {
              width: 25px;
              height: 25px;
              float: unset;
              margin: 0;
            }

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }
          }
        }

        .selections.inline-selection {
            flex-direction: row;
        }
      }

      .inline-label {
        flex-direction: row !important;
        flex-wrap: nowrap;
        align-items: center;
      }

      .row-group {
        .input-group {
          align-items: flex-start;

          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }

          input,
          textarea {
            width: 100%;
            border-radius: 7px;

            &::placeholder {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
