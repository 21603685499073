.form-tab-table {
    .record-type-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .add_record_btn {
            border-radius: 0 !important;
            margin-top: 10px;
            border-right: 2px solid #198754;
            border: 2px solid #198754;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 600;
        }

        .filters {
            margin-top: 10px;
            display: flex;
            align-items: center;

            .filter-period-label {
                font-size: 14px;
                margin-bottom: 0;
                color: #78909C;
            }
            
            .input-group {
                margin-left: 10px;
                margin-bottom: 0 !important;
                
                * {
                    border-radius: 0;
                    font-size: 14px;
                }

                input {
                    border-right: 1px solid #ced4da;
                    box-shadow: none;
                }

                input[type="month"]::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                }
            }
        }
    }

    .list-group-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.125);

        p {
            margin-bottom: 0;
            font-size: 12px;
            color: #78909C;
        }

        button {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 600;
            padding: 6px 12px;
            border-radius: 0;
            border-right: 2px solid #0d6efd;
            border: 2px solid #0d6efd;
        }
    }

    .no-record-found {
        font-size: 14px;
        // color: #78909C !important;
        // border-color: darken(#b6effb, 15%);
        // border-right: 1px solid darken(#b6effb, 15%);
        border-radius: 0;
    }
}