#confirmation-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
    background: #FF4A4A !important;

    h6 {
        margin: 0;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
    }

    button {
        padding: 0;
        border: none;
        background: none;

        &:focus,
        &:hover {
            border: none;
            background: none;
            box-shadow: none;
        }

        svg {
            width: 32px;
        }
    }
}

.loginModal {

    .modalUI {

        .modal-body {
            padding: 2em;

            .loginModal__svgContainer {
                display: flex;
                justify-content: center;

                svg {
                    width: 175px;
                }
            }

            p {
                font-size: 18px;
                text-align: center;
            }
        }

        .modal-footer {
            padding: 1em 2em 2em;

            .loginModal__button {
                margin: 0 auto;
                padding: 0.5em 1em;
                color: #ffffff;
                font-size: 18px;
                text-transform: uppercase;
                border: none;
                border-radius: 10px;
                background: #07c900;
            }
        }
    }
}