.outpatient-record-form {
  .row-group {
    .input-group.small {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;

      input.form-control {
        flex: 1 !important;
      }
    }
  }
}
