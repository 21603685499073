.patientProfile__header {
    .action-buttons {
        .badge {
            display: block;
            
            &.red {
                background-color: #FF616C;
                color: #fff;
            }
        }
    }
}
li{
    list-style: none;
}