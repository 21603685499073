#print_discharge_profile {
    padding: 20px;
    margin: auto 20px;

    .header {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 170px;
            height: auto;
        }

        h1 {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            margin-top: 15px;
        }

        h3 {
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            // color: #6c757d
        }
    }

    .profile_content {
        .field {
            display: flex;
            flex-direction: row;

            p {
                padding-right: 50px;
                color: #000;
                font-weight: bold;
                font-size: 14px;
            }

            span {
                font-size: 14px;
            }
        }

        .patient-banner {
            background-color: #dee2e6 !important;
            border: 1px solid darken(#dee2e6, 10%);
        }

        .discharge-table {
            h5 {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }

    table {
        font-size: 12px;
        border: 1px solid #dee2e6;

        /* thead {
            background-color: #00a8ff;
            border: 1px solid #00a8ff;
        } */

        thead > tr > th {
            // border-right: 1px solid #dee2e6;
            // text-align: center;
            font-weight: bold;
            // color: white
            text-transform: uppercase;
            border: none;
            color: #000;
        }

        tbody > tr > td {
            // border-right: 1px solid #dee2e6;
            font-weight: 500;
            border: none;
        }
    }
}