.patient_selection {
    #search_patient {
        border-color: black;
        border-radius: 0;
        font-size: 14px;
        box-shadow: none;
    }

    ::placeholder {
        color: #90A4AE;
    }

    #patient_list {
        border-radius: 0;
        font-size: 14px;
    }

    .offcanvas_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .list_pagination {
            // display: flex;
            // justify-content: flex-end;
            margin-bottom: 0;
    
            li > span:last-of-type,
            li > span:first-of-type {
                border-radius: 0;
            }
    
            li > a:last-of-type,
            li > a:first-of-type {
                border-radius: 0;
            }
        }

        button {
            border-radius: 0;
            font-size: 12px;
            padding: 6px 12px;
        }
    }
}