.admissions-inventory-list-table {
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .print-button.btn {
      background-color: #ff616c;
      border: none;
    }
  }

  .tableUI {
    .table {
      .badge.text-white {
        // color: #000 !important;
      }

      .badge {
        font-size: 12px;

        &.red {
          background: #ff0000;
        }

        &.green {
          background: #02961c;
        }

        &.blue {
          background: #00a8ff;
        }

        &.Pending {
          background: #0079c933;
          color: #0079c9;
        }

        &.In.use {
          background: #0097144d;
          color: #009714;
        }

        &.Denied {
          background: #ff616c66;
          color: #e20010;
        }

        &.Returned {
          background: #ffd5004d;
          color: #b88100;
        }

        &.borrowed {
          background: #0097144d;
          color: #0097144d;
        }

        &.completed {
          background: #009714;
          color: #000;
        }
      }

      thead {
        tr {
          th {
            font-size: 12px;
            width: 1ch;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 12px;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }

  .fw-bold {
    font-weight: 700;
  }

  .button-status {
    margin-top: 15px;
    position: relative;

    .btn {
      padding: 3px 15px;
      text-transform: none;
    }

    .btn-open {
      background-color: #0069f2;
      color: white;
    }

    .btn-secondary {
      background-color: #c8c8c8;
    }

    .btn-request-item {
      position: absolute;
      right: 0;
      background-color: #06b10c;
      color: white;
    }
  }
}

.admissions-inventory-view-items-details-modal {
  .modal-body .fw-bold {
    color: #00a8ff;
  }
}
