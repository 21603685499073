@media print {
  @page {
    margin: 1.5cm 1cm;
  } 
  .print_body {
    header {
      display: flex;
      align-items: center;
      flex-direction: column;

      .header_logo {
        margin: 15px auto;
        img {
            text-align: center;
            width: 170px;
            height: auto;
        }
      }

      .header_title {
        font-weight: bold;
        font-size: 16px;
        color: black !important;
      }

      .header_subtitle {
        margin-top: 5px;
        font-weight: bold;
        font-size: 14px;
        color: black !important;
      }
    }
    .first-row {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;

      .input-group {
        width: 50%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 5px;

        p {
          flex: 1;
          width: 100%;
          font-size: 1rem;
        }

        label {
          font-weight: bold;
        }
      }
    }

    .second-row {
      display: flex;
      flex-direction: column;
      // gap: 1rem;
      justify-content: center;
      align-items: flex-end;

      .input-group {
        width: 50%;
        max-width: 400px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        gap: 10px;

        p {
          margin: 0;
          padding: 0;
          width: 100%;
          font-size: 1rem;
        }

        label {
          font-weight: bold;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
        }
      }
    }

    .third-row {
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        margin: 0;
        font-weight: bold;
      }
    }

    .bordered-container {
      border: solid 1px #707070;
      border-radius: 5px;
      padding: 1rem;
      display: flex;
      .first-column,
      .second-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        h5 {
          font-size: 1rem;
          font-weight: bold;

          span {
            display: block;
            font-style: italic;
            font-size: 12px;
            padding: 4px 0 0 4px;
          }
        }

        .input-group {
          display: flex;
          // align-items: center;
          gap: 10px; 

          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }

          P {
            font-size: 13px;
            height: 33px;
          }
        }

        .inline-label {
          display: flex;
          gap: 5px;
          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }

          p {
            font-size: 13px;
            margin: 0;
            padding: 0;
          }
        } 

        .checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;

          .form-label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }

          .selections {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .form-check {
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 0;

              label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }

              input {
                width: 25px;
                height: 25px;
                float: unset;
                margin: 0;
              }
            }
          }
        }

        .one-row {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;

          .input-group {
            flex-direction: column;
            align-items: self-start;
            gap: 5px;

            input {
              width: 100%;
              border-radius: 7px !important;
              font-size: 13px;
              height: 33px;
            }
          }

          .sex {
            flex: 0.5;

            .sex-selection {
              display: flex;
              gap: 10px;

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                label {
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 13px;
                }

                input {
                  width: 25px;
                  height: 25px;
                  float: unset;
                  margin: 0;
                }
              }
            }
          }

          .age {
            flex: 0 0 10rem;
          }

          .citizenship {
            flex: 0 0 10rem;
          }

          .occupation {
            flex: 2;
          }
        }

        &.large-width {
          flex: 2;
        }
      }

      .third-column {
        flex: 3;
        display: flex;
        flex-direction: column;
        // gap: 10px;
        justify-content: flex-start;
        align-items: center;  
        h5 {
          font-size: 1rem;
          font-weight: bold;
        }

        .row-cont {
          display: flex;
          align-items: center;
          width: 100%;
          
          .input-group {
            display: flex;
            flex-direction: column;

            label {
              margin: 0;
              padding: 0;
              // font-weight: bold;
              font-size: 13px;
              align-self: start;
            }

            p {
              margin: 0;
              padding: 0;
              font-size: 13px;
              // font-weight: bold;
            }
          }

          .date-time,
          .type {
            display: flex;
            align-items: center;
            // gap: 10px;

            label {
              margin: 0;
              padding: 0;
              // font-weight: bold;
              font-size: 13px;
            }

            p {
              font-size: 13px;
              height: 33px;
              // font-weight: bold;
            }
          }

          .date-time {
            flex: 1.5;
          }

          .type {
            flex: 1;

            p {
     
              font-size: 13px;
              height: 33px;
              // font-weight: bold;
            }
          }
        }
      }
    }
  }
}

