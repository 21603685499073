.tableUI {
  border-top: 1px solid rgba(0, 168, 255, 0.2);

  .table {
    margin: 1em 0;

    & > :not(:first-child) {
      border: none;
    }

    thead {
      tr {
        th {
          padding: 1em;
          color: #ffffff;
          // line-height: 120%;
          background: #00a8ff;
          vertical-align: middle;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 1em;
          font-size: 15px;
          border: none;
          vertical-align: middle;
        }

        td:last-child {
          svg {
            width: 40px;
            fill: #6c6c6c;
            cursor: pointer;
          }
        }
      }

      tr:nth-child(2n) {
        td {
          background: #ececec;
        }
      }
    }
  }

  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter .buttons {
    display: flex;
    flex: 1 1;
  }

  .filter .manda-pagination {
    margin-left: auto;
    padding-left: 20px;
  }

  .accordion {
    background: none;

    .accordion-item {
      border: none;
      background: none;

      .accordion-header {
        .accordion-button,
        .accordion-button.collapsed {
          background: red;
        }
      }

      .accordion-collapse,
      .accordion-collapse.collapse {
        position: relative;
        .accordion-body {
          position: relative;
          top: -10px;
          left: 0;
          padding: 1em 0;
          background: blue;
        }
      }
    }
  }
}
