.dashboardCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 260px;
  height: 200px;
  margin: 1em;
  padding: 1.5em 1em;
  border: 5px solid #00a8ff;
  border-radius: 10px;

  svg {
    width: 80px;
    margin-bottom: 1.5em;
    fill: #00a8ff;
  }

  h5 {
    margin: 0 auto;
    color: #00a8ff;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }
}
