.topbar {
  display: flex;
  align-items: center;
  height: 74px;
  padding: 1em 1.5em;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(83, 83, 83, 0.2);

  & > button {
    margin: 0;
    padding: 0.25em;
    margin-right: 1.25em;
    border: none;
    background: none;
    box-shadow: none;
    outline: none;

    & > svg:nth-of-type(1) {
      width: 30px;
      fill: #00a8ff;
    }

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
      outline: none;
    }
  }

  & > .input-group {
    & > .input-group-text {
      border: 1px solid #d4d4d4;
      border-right: none;
      border-radius: 10px;
      background: none;

      svg {
        width: 20px;
      }
    }

    & > .form-control {
      width: 100%;
      max-width: 280px;
      padding: 0.5em 1em 0.5em 0;
      border: 1px solid #d4d4d4;
      border-left: none;
      border-radius: 10px;

      &::placeholder {
        color: #d4d4d4;
      }

      &:focus,
      &:hover {
        background: none;
        box-shadow: none;
      }
    }
  }

  & > svg {
    width: 40px;
    fill: #00a8ff;
  }

  Button.btn.btn-primary {
    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
      outline: none;
    }
  }

  .search-container {
    width: 300px;
  }

  @media (max-width: 1200px) {
    padding: 10px;

    Button.btn.btn-primary {
      margin-right: 10px;

      svg {
        width: 25px;
      }
    }

    .search-container {
      width: 250px;

      .select-search {
        span {
          font-size: 14px;
        }
      }

      // . css-6j8wv5-Input
    }
  }

  @media (max-width: 565px) {
    .search-container {
      width: 180px;
    }
  }
}
