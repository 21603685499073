.paginationUI {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  // margin: 1em 0;

  .summary {
    display: flex;
    align-items: center;
    margin-right: 1em;
    padding: 0.25em 0.5em;
    color: #ffffff;
    font-size: 14px;
    border-radius: 5px;
    background: #06b10c;
  }

  .selectWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .form-label {
      margin: 0 0.5em 0 0;
      color: #000000;
      font-size: 14px;
      line-height: 100%;
    }

    & > .form-select {
      width: initial;
      margin-right: 1em;
      padding-top: 0.25em;
      padding-bottom: 0.25em;
      border: 1px solid #707070;
      border-radius: 5px;
    }
  }

  .pagination {
    margin: 0;
    padding: 0;

    .page-item {
      margin: 0 0.25em;
      min-width: 35px;

      &.active .page-link {
        color: #ffffff;
        border-color: #00a8ff;
        background: #00a8ff;
        cursor: not-allowed;
      }
    }

    .page-link {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 14px;
      border: 1px solid #707070;
      border-radius: 10px;
      background: transparent;
    }
  }

  .pagination__summary {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: #4d4d4d;
      font-size: 0.98rem;
    }
  }
}
