.admissions-search-inventory-filter {
  label {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  button {
    background-color: #c8c8c8;
    border: none;
    height: min-content;
  }

  .btn-success {
    background-color: #07c900;
  }

  .search {
    width: 18rem;
  }

  .borrow-item-container {
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .borrow-item {
      height: 50px;
      border: none;
      background-color: #009714;
    }

    .print-button.btn {
      height: 50px;
      border: none;
      background-color: #ff616c;
    }
  }
}