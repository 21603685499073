.discharge-summary {
  .Filter {
    .buttonWrapper {
      display: flex;
      justify-content: flex-start;
      gap: 1em;
      height: 100%;
      align-items: flex-end !important;

      button.btn.btn-search {
        background-color: #07c900;
        height: 50px;
      }

      button.btn.btn-clear {
        background-color: #c8c8c8;
        height: 50px;
      }
    }

    label {
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }

    .basic-search {
      .bg-grey.form-control {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }

      .btn.btn-search {
        margin-left: 1em !important;
        border-radius: 10px !important;
      }
    }

    .advanced-search {
      .accordion-item {
        border: none;

        .accordion-header {
          .accordion-button {
            font-weight: bold;
            padding: 1rem 1.5rem;
            color: #00a8ff;
            background-color: #fff;
            box-shadow: none;
            border-bottom: 2px solid #000;

            &.collapsed {
              color: #000;
              border-bottom: none;
            }

            &::after {
              margin-right: auto;
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }

    .form-check {
      position: relative;

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
      }
    }
  }

  .discharge-summary-table {
    h2 {
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0 0 1rem;
      padding: 0 0 1rem;
      border-bottom: solid 1px #00a8ff;
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: flex-start;

      button.active {
        background-color: #0069f2;
        width: 100%;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
        flex: 0 0 15rem;
        font-size: 13px;
        width: 100%;
      }
    }

    .table-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        background-color: #00a8ff;
        border: none;
        text-transform: capitalize;
      }

      button.btn.btn-search {
        background-color: #02961c;
      }
    }

    .tableUI {
      border-top: none;

      .table {
        thead {
          tr {
            th {
              padding: 10px;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 10px;

              .awaiting-bed {
                background: #ffcc00;
              }

              span.Currently.Admitted {
                background-color: #06b10c;
                color: #fff;
                padding: 5px;
                border-radius: 5px;
              }

              span.Discharge {
                background-color: #ff6e6e;
                color: #fff;
                padding: 5px;
                border-radius: 5px;
              }
            }

            td:nth-of-type(2) {
              font-weight: bold;
            }

            td.action {
              display: flex;
              align-items: center;
              gap: 5px;

              button.adjust {
                padding: 3px 8px;
                border-radius: 5px;
                font-size: 10px;
                background-color: #00a8ff;
                height: max-content;
              }

              button.issue {
                padding: 3px 8px;
                border-radius: 5px;
                font-size: 10px;
                height: max-content;
                background-color: #ff0060;
              }

              button {
                border: none;

                svg {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }
      }
    }

    .popoverButton:focus {
      box-shadow: none;
    }
  }

  .view-profile {
    .header-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      Button.btn.print {
        background-color: #ff6e6e;
        border: none;
      }

      Button.btn.back-button {
        color: #00a8ff;
        font-weight: bold;
        background: none;
        border: none;
        position: relative;
        text-transform: capitalize;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          margin: auto;
          top: 0;
          border-right: 12px solid #00a8ff;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .avatar-container {
        border-radius: 50%;
        border: solid 2px #707070;
        padding: 1rem;

        svg {
          height: 160px;
          width: 160px;
          object-fit: contain;
        }
      }

      .profile-name {
        text-align: center;

        h4 {
          margin: 0 0 0.5rem;
          font-size: 2rem;
        }

        h6 {
          font-size: 1.5rem;
          margin: 0 0 1rem;
          text-transform: capitalize;
          font-weight: bold;

          span {
            color: #00a8ff;
          }
        }

        .info {
          display: flex;
          justify-content: space-around;
          align-items: center;

          p {
            margin: 0;
            font-weight: bold;
          }
        }
      }
    }

    .patient-banner {
      display: flex;
      justify-content: space-between;
      margin: 2em 0;
      padding: 0.5em;
      border-radius: 10px;
      background: #00a8ff;

      .right {
        border-right: solid 1px #fff;
      }

      .left {
        border-left: solid 1px #fff;
      }

      h3,
      h6 {
        color: #ffffff;
        margin: 0;
      }

      .row {
        width: 100%;

        .col-lg-6 {
          border-right: 1px solid #ffffff;
          border-left: 1px solid #ffffff;
        }
      }

      span {
        color: #ffffff;
      }

      .allergies {
        span {
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }

    .discharge-table {
      h5 {
        font-weight: bold;
        margin: 0 0 1rem;
        padding: 0 0 1rem;
        border-bottom: solid 1px #00a8ff;
      }

      .tableUI {
        border: none;
        margin: 0;

        .table {
          tbody {
            tr {
              td {
                font-weight: bold;

                p {
                  margin: 0;
                  font-weight: bold;

                  span {
                    display: block;
                    font-size: 12px;
                  }
                }

                .link {
                  background-color: #00a8ff;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                  text-decoration: none;
                }
              }

              td.Cleared {
                span {
                  background-color: #00b112;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                }
              }

              td.COMPLETED {
                span {
                  background-color: #00b112;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                }
              }

              td.PENDING {
                span {
                  background-color: #00a8ff;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                }
              }

              td.Ongoing {
                span {
                  background-color: #00a8ff;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}