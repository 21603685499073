#print-patient-profile {
  padding: 0;

  .header {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 170px;
      height: auto;
    }

    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      margin-top: 15px;
    }

    h3 {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      // color: #6c757d
    }
  }

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 15px;
  }

  h3 {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    // color: #6c757d
  }

  .formPatientProfile__banner--print {
    display: flex;
    justify-content: space-between;
    margin: 2em 0;
    padding: 0.5em;
    border-radius: 10px;
    background: #00a8ff;

    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      margin-top: 15px;
    }

    h3 {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      // color: #6c757d
    }
  }

  .patientProfile__header {
    margin: 1em 0 1.5em;

    h4 {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid rgba(0, 168, 255, 0.2);
    }
  }

  .patientProfile__content {
    margin-top: 0.5em;

    h4 {
      font-size: 16px;
      margin-bottom: 1em;
      font-weight: bold;
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1em;
    }

    .value {
      color: #006397;
      font-weight: bold;
      text-transform: uppercase;
    }

    .value--restriction {
      color: #ff0000;
    }

  }
}