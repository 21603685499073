.nursing-discharge-summary-action-table {
  .tableUI {
    .table {
      thead {
        tr {
          th {
            padding: 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px;

          }

          span {
      
            background-color: #00A8FF;
            color: #fff;
            text-align: center;

            border-radius: 7px;
            width: fit-content;
            padding: 0 10px;
            margin: 0 auto;
            min-width: 90px;


            &.pending {
              background-color: #cbb000;
            }

            &.approved {
              background-color: #07c900;
            }

            &.admitted {
              background-color: #E2BD00;
            }
          }
        }
      }
    }
  }

  .popoverButton:focus {
    box-shadow: none;
  }
}