#print_subpoena-duces-tecum {
  padding: 20px;

  .header {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 170px;
      height: auto;
    }

    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      margin-top: 15px;
    }

    h3 {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      // color: #6c757d
    }
  }

  p {
    font-size: 12px;
  }

   .titleFlex {
     display: flex;
     flex-direction: column;
     align-items: center;
   }

  .startFlex {
    display: flex;
    align-items: flex-start;

    gap: 10px;
  }

}
