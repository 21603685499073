.discharge-summary {
  .main-box-container {
    border: solid 1px #707070;
    border-radius: 5px;

    .box-cont {
      border-bottom: solid 1px #707070;
      padding: 1rem;

      h3 {
        margin: 0 0 10px;
        padding: 0;
        font-weight: bold;
        font-size: 16px;
      }

      .inline-row {
        display: flex;
        gap: 10px;

        p.inline-header {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
          flex: 0 0 auto;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .input-group {
    flex-direction: column;
    display: flex;
    gap: 10px;

    label {
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-size: 13px;
    }

    input {
      border-radius: 7px !important;
      font-size: 13px;
      height: 33px;

      &::placeholder {
        font-weight: normal;
      }
    }
    .form-select {
      border-radius: 7px !important;
      font-size: 13px;
      height: 33px;
      width: 100%;
      color: #6c757d;
    }
    textarea {
      font-size: 13px;
      font-weight: bold;
      border-radius: 7px !important;
    }

    input,
    textarea {
      width: 100%;
      border-radius: 7px;

      &::placeholder {
        font-size: 13px;
        font-weight: normal;
      }
    }

    .selections {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;

      .form-check {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0;

        input {
          width: 25px;
          height: 25px;
          float: unset;
          margin: 0;
        }

        label {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
        }
      }
    }

    .selections.inline-selection {
      flex-direction: row;
    }
  }

  .inline-label {
    flex-direction: row !important;
    flex-wrap: nowrap;
    align-items: center;
  }

  .inline-label-textarea {
    flex-direction: row !important;
    flex-wrap: nowrap;
    align-items: flex-start;

    label {
      flex: 0 0 auto;
    }
  }

  .inline-label-textarea.flex-label {
    label {
      flex: 0 0 10rem;
    }
  }

  .row-group {
    .input-group {
      align-items: flex-start;

      label {
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: 13px;
      }

      input,
      textarea {
        width: 100%;
        border-radius: 7px;

        &::placeholder {
          font-size: 13px;
        }
      }
    }
  }

  .header-textbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-group {
      flex: 0 0 300px;

      input {
        border-radius: 7px !important;
        font-size: 13px;
        height: 33px;

        &::placeholder {
          font-weight: normal;
        }
      }
    }
  }

  .two-input {
    .cont {
      display: inline-block;
    }

    .input-group {
      width: min-content;
      max-width: 12rem;

      label {
        flex: 0 0 auto;
      }

      input {
        flex: 0 0 150px;
      }
    }
  }

  .right-cont {
    // justify-content: flex-end;

    // input {
    //   flex: 0 0 60rem;
    // }

    .cont {
      .input-group {
        input {
          flex: 0 0 16rem;
        }
      }
    }
  }

  .signature {
    position: relative;
    min-width: 260px;

    p.over-printed-name {
      position: absolute;
      font-size: 13px !important;
      font-weight: bold;
      bottom: -2rem;
      left: 0;
      right: 0;
      text-align: center;
      margin: auto;
    }

    input {
      font-size: 13px !important;
      width: 100% !important;
    }
  }
}
