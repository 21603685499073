.formPatientProfile {
  // padding: 2em;
  border-radius: 10px;
  background: #ffffff;

  .formPatientProfile__header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
      color: #00a8ff;
      font-weight: bold;
    }
  }

  .avatar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    padding: 2em 0;

    .uploadedAvatar {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      border-radius: 80px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .patient__number {
      color: #00a8ff;
      font-weight: 600;

      span {
        color: black;
      }
    }

    .patient__info {
      display: flex;
      justify-content: space-between;
      width: 100%;


      span {
        margin-left: 50px;
      }
    }
  }

  .svg__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    border: 2px solid #707070;
    border-radius: 110px;

    svg {
      width: 140px;
    }
  }

  .formPatientProfile__banner {
    display: flex;
    justify-content: space-between;
    margin: 2em 0;
    padding: 0.5em;
    border-radius: 10px;
    background: #00a8ff;

    h3,
    h6 {
      color: #ffffff;
    }

    .row {
      width: 100%;

      .col-lg-6 {
        border-right: 1px solid #ffffff;
        border-left: 1px solid #ffffff;
      }
    }

    span {
      color: #ffffff;
    }
  }

  .form-label {
    width: 100%;
    cursor: pointer;

    .fakeInput {
      position: relative;
      width: 100%;
      min-width: 280px;
      padding: 0.75em 1em;
      color: #c8c8c8;
      border: 1px solid #c8c8c8;
      border-radius: 10px;
      background: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        position: absolute;
        top: -1px;
        right: -1px;
        padding: 0.75em 1em;
        color: #ffffff;
        border: 1px solid #0057c9;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background: #0057c9;
      }
    }
  }

  .form-control[type='file'] {
    display: none;
  }

  .formUI {
    .form-label {
      font-size: 14px;
      font-weight: bold;
    }

    .patientIdentity__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .patientIdentity {
        position: relative;
        flex: 1 0 45%;
        max-width: 720px;
        margin: 1em 1em 1em 0;
        padding: 1.5em;
        border-radius: 10px;
        background: #f5f5f5;

        .identityDocuments {
          width: 100%;
          background: #f5f5f5;
        }

        button {
          position: absolute;
          top: -10px;
          right: 10px;
        }
      }
    }

    .familyDetails__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .familyDetails {
        position: relative;
        margin: 1em 1em 1em 0;
        padding: 1.5em;
        border-radius: 10px;
        background: #f5f5f5;
      }

      button {
        position: absolute;
        top: -10px;
        right: 10px;
      }
    }

    .accordionUI {
      .accordion-header {

        &>.accordion-button,
        &>.accordion-button.collapsed {
          color: #e9ca00;
          font-size: 20px;
          font-weight: bold;
          border-bottom: 2px solid #e9ca00;
        }

        &>.accordion-button:not(.collapsed)::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E9CA00'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
      }
    }
  }
}