.dashboardBanner {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: 3em 2em 0;
  border-radius: 10px;
  background: #00a8ff;

  & > .dashboardBanner__logo {
    width: 120px;
    margin: 2em 0 2em 2em;
  }

  & > .divider {
    width: 2px;
    height: 90px;
    margin: 0 3em;
    background: #ffffff;
  }

  & > h1 {
    margin: 0;
    color: #ffffff;
    line-height: 130%;
  }

  & > svg {
    position: absolute;
    right: 10px;
    bottom: -5px;
    width: 240px;
  }
}
