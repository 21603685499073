.headerButtonsContainer{
    display: flex;
    justify-content: flex-end;
}

.gridContainer{
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    row-gap: 10px;

    .gridItem{
        display: flex;
        justify-content: space-between;
        
        input, textarea{
            margin-left: 10px;
        }
    }
}