.dashboardCardList {
  margin: 2em;

  .dashboardCardList__header {
    margin-bottom: 2em;
    padding: 1em 0;
    border-bottom: 1px solid #00a8ff;

    & > h4 {
      color: #00a8ff;
      font-weight: bold;
    }

    & > span {
      font-size: 18px;
    }
  }

  .dashboardCardList__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
