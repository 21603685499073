.laboratory-create-lab-table {
  h2 {
      font-size: 20px;
      font-weight: bold;
  }

  .tableUI {
    .table {
      tbody {
        tr {
          td {
            span {
                padding: 5px;
                color: #fff;
                border-radius: 5px;
            }

            span.active {
                background-color: #07C900;
            }

            span.in-active {
                background-color: #ff7878;
            }
          }
        }
      }
    }
  }
}