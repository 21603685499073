.medical-record-form {
    .row-group {
        .input-group.small {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;

            input.form-control {
                flex: 1 !important;
            }
        }
    }

    .bordered-container.information {
        .right-cont {
            .row-group {
                gap: 10px;
                flex-wrap: wrap;
                justify-content: flex-start;

                .small {
                    width: 15rem;
                    flex-direction: row;
                }

                .big {
                    width: 20rem;
                    flex-direction: row;
                }
            }
        }
    }

    .bordered-container.condition-on-arrival {
        flex-wrap: wrap;

        .info {
            padding: 2rem 1rem !important;
        }
    }
}