.laboratory-result-table {
    .tableUI {
        .table {
            .custom-actions {
                gap: 3px;
                flex: 1 1;
                display: flex;
                margin-top: -16px;
                margin-bottom: -16px;
                flex-direction: column;
            }

            tbody {
                tr {
                    // td:nth-of-type(3) {
                    //   font-weight: bold;
                    // }

                    .btn.view-result {
                        background-color: #07c900;
                        color: #ffff;
                        border-radius: 7px;
                        font-size: 12px;
                        padding: 2px 10px;
                        border: none;
                    }

                    span {
                        display: block;
                        background-color: #00A8FF;
                        color: #fff;
                        text-align: center;
                        padding: 2px 0;
                        border-radius: 7px;
                        width: fit-content;
                        padding: 0 10px;
                        margin: 0 auto;
                        min-width: 90px;

                        &.pending {
                            background-color: #cbb000;
                        }

                        &.approved {
                            background-color: #07c900;
                        }
                    }

                    span.awaiting-bed {
                        background-color: #E2BD00;
                        cursor: pointer;
                    }

                    span.available {
                        background-color: #06B10C;
                        cursor: pointer;
                    }

                    span.busy {
                        background-color: #FF5050;
                    }

                    span.select-button {
                        cursor: pointer;
                        background-color: #00A8FF;
                    }
                }
            }
        }

        .buttons {
            display: flex;
            gap: 1em;
            align-items: center;

            button.active {
                background-color: #0069f2;
            }

            button {
                background-color: #c8c8c8;
                border: none;
                height: min-content;
            }

            .specialty {
                display: flex;

                select {
                    padding: 8px 40px 8px 20px;
                }
            }
        }

        .assesment-pagination {
            height: 100%;

            .paginationUI {
                margin: 0;
                align-items: center;

                .pagination {
                    .page-item {
                        margin: 0 0.25em;
                        display: flex;
                        align-items: center;
                    }
                }

                .summary {
                    background: #06b10c;
                    height: fit-content;
                    flex-direction: column;
                }
            }
        }
    }

    .popoverButton:focus {
        box-shadow: none;
    }
}

.assesment-pagination {
    .paginationUI {
        .selectWrapper {
            .form-select {
                padding: 0.375rem 2.25rem 0.375rem 0.75rem;
            }
        }
    }
}

#file-link {
    color: white;
    text-decoration: none;
}