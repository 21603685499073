.form-tab {
  .Filter {
    .buttonWrapper {
      display: flex;
      justify-content: flex-start;
      gap: 1em;
      height: 100%;
      align-items: flex-end !important;

      button.btn.btn-search {
        background-color: #07c900;
        height: 50px;
      }

      button.btn.btn-clear {
        background-color: #c8c8c8;
        height: 50px;
      }
    }

    label {
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }

    .basic-search {
      .bg-grey.form-control {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }

      .btn.btn-search {
        margin-left: 1em !important;
        border-radius: 10px !important;
      }
    }

    .advanced-search {
      .accordion-item {
        border: none;

        .accordion-header {
          .accordion-button {
            font-weight: bold;
            padding: 1rem 1.5rem;
            color: #00a8ff;
            background-color: #fff;
            box-shadow: none;
            border-bottom: 2px solid #000;

            &.collapsed {
              color: #000;
              border-bottom: none;
            }

            &::after {
              margin-right: auto;
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }

    .form-check {
      position: relative;

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
      }
    }
  }

  .form-tab-table {
    h2 {
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0 0 1rem;
      padding: 0 0 1rem;
      border-bottom: solid 1px #00a8ff;
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: flex-start;

      button.active {
        background-color: #0069f2;
        width: 100%;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
        flex: 0 0 15rem;
        font-size: 13px;
        width: 100%;
      }
    }

    .table-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        background-color: #00a8ff;
        border: none;
        text-transform: capitalize;
      }

      button.btn.btn-search {
        background-color: #02961c;
      }
    }

    .tableUI {
      border-top: none;

      .table {
        thead {
          tr {
            th {
              padding: 10px;
            }

            th:nth-child(2) {
              text-align: right;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 0;

              .awaiting-bed {
                background: #ffcc00;
              }

              span.Currently.Admitted {
                background-color: #06b10c;
                color: #fff;
                padding: 5px;
                border-radius: 5px;
              }

              span.Discharge {
                background-color: #ff6e6e;
                color: #fff;
                padding: 5px;
                border-radius: 5px;
              }

              .form-columns.accordion {
                overflow: hidden;

                .accordion-item {
                  .accordion-header {
                    margin: 5px 0;
                    padding: 0;
                    border: none;

                    .accordion-button {
                      background-color: #ececec;
                      color: #262626;
                    }
                  }

                  .accordion-body {
                    background-color: #fff;
                    padding: 10px;

                    .add-record {
                      border-radius: 5px;
                      background-color: #01b425;
                      border: none;
                      font-size: 14px;
                      padding: 5px 0.5rem;
                      display: flex;
                      align-items: center;

                      svg {
                        fill: #fff;
                        margin-right: 8px;
                        width: 20px;
                      }
                    }

                    ul {
                      padding: 0;
                      margin: 0;
                      display: flex;
                      flex-direction: column;
                      gap: 10px;

                      li {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        p {
                          margin: 0;
                          font-weight: bold;
                        }

                        Button.btn.view-records {
                          background-color: #00a8ff;
                          border: none;
                          text-transform: capitalize;
                          border-radius: 5px;
                          padding: 5px;
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }
              }
            }

            td:nth-of-type(2) {
              font-weight: bold;
            }

            td.action {
              display: flex;
              align-items: center;
              gap: 5px;

              button.adjust {
                padding: 3px 8px;
                border-radius: 5px;
                font-size: 10px;
                background-color: #00a8ff;
                height: max-content;
              }

              button.issue {
                padding: 3px 8px;
                border-radius: 5px;
                font-size: 10px;
                height: max-content;
                background-color: #ff0060;
              }

              button {
                border: none;

                svg {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }
      }
    }

    .popoverButton:focus {
      box-shadow: none;
    }
  }

  .view-profile {
    .header-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      Button.btn.print {
        background-color: #ff6e6e;
        border: none;
      }

      Button.btn.back-button {
        color: #00a8ff;
        font-weight: bold;
        background: none;
        border: none;
        position: relative;
        text-transform: capitalize;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          margin: auto;
          top: 0;
          border-right: 12px solid #00a8ff;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .avatar-container {
        border-radius: 50%;
        border: solid 2px #707070;
        padding: 1rem;

        svg {
          height: 160px;
          width: 160px;
          object-fit: contain;
        }
      }

      .profile-name {
        text-align: center;

        h4 {
          margin: 0 0 0.5rem;
          font-size: 2rem;
        }

        h6 {
          font-size: 1.5rem;
          margin: 0 0 1rem;
          text-transform: capitalize;
          font-weight: bold;

          span {
            color: #00a8ff;
          }
        }

        .info {
          display: flex;
          justify-content: space-around;
          align-items: center;

          p {
            margin: 0;
            font-weight: bold;
          }
        }
      }
    }

    .patient-banner {
      display: flex;
      justify-content: space-between;
      margin: 2em 0;
      padding: 0.5em;
      border-radius: 10px;
      background: #00a8ff;

      .right {
        border-right: solid 1px #fff;
      }

      .left {
        border-left: solid 1px #fff;
      }

      h3,
      h6 {
        color: #ffffff;
        margin: 0;
      }

      .row {
        width: 100%;

        .col-lg-6 {
          border-right: 1px solid #ffffff;
          border-left: 1px solid #ffffff;
        }
      }

      span {
        color: #ffffff;
      }

      .allergies {
        span {
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }

    .discharge-table {
      h5 {
        font-weight: bold;
        margin: 0 0 1rem;
        padding: 0 0 1rem;
        border-bottom: solid 1px #00a8ff;
      }

      .tableUI {
        border: none;
        margin: 0;

        .table {
          tbody {
            tr {
              td {
                font-weight: bold;

                p {
                  margin: 0;
                  font-weight: bold;
                  span {
                    display: block;
                    font-size: 12px;
                  }
                }

                .link {
                  background-color: #00a8ff;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                  text-decoration: none;
                }
              }

              td.Cleared {
                span {
                  background-color: #00b112;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                }
              }

              td.Ongoing {
                span {
                  background-color: #00a8ff;
                  color: #fff;
                  padding: 5px;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .view-form {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 1rem;
      border-bottom: solid 2px #00a8ff;

      Button.btn {
        background: none;
        color: #00a8ff;
        border: none;
        font-weight: bold;
        padding: 0;
      }

      h4 {
        font-weight: bold;
        margin: 0;
      }
    }

    .form-cont {
      .blue-header-container {
        border: solid 1px #707070;
        border-radius: 5px;

        h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #00a8ff;
          padding: 4px 0;
          color: #fff;
          font-weight: bold;
          font-size: 1.2rem;
          margin: 0;
        }

        .box-cont {
          border-top: solid 1px #707070;
          border-bottom: solid 1px #707070;
          border-radius: 0;
          padding: 1rem;

          .info {
            flex: 1;
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0;

            h6 {
              margin: 0;
              padding: 0;
              font-size: 1.2rem;
              font-weight: bold;
              text-align: center;
            }

            h5 {
              font-size: 1rem;
              font-weight: bold;

              span {
                display: block;
                font-style: italic;
                font-size: 12px;
                padding: 4px 0 0 4px;
              }
            }

            .input-group {
              display: flex;
              align-items: center;
              gap: 10px;

              label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }

              input {
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;

                &::placeholder {
                  font-weight: normal;
                  font-size: 13px;
                }
              }

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                label {
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 13px;
                }

                input {
                  width: 25px;
                  height: 25px;
                  float: unset;
                  margin: 0;
                }
              }
            }

            .checkbox {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 20px;

              .form-label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }

              .selections {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                gap: 20px;

                .form-check {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  padding: 0;

                  label {
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    font-size: 13px;
                  }

                  input {
                    width: 25px;
                    height: 25px;
                    float: unset;
                    margin: 0;
                  }
                }
              }
            }

            .two-sides {
              display: flex;
              gap: 0;
              flex-wrap: nowrap;
              justify-content: space-evenly;

              hr {
                width: 1px;
                height: 125%;
                background-color: #707070;
                position: relative;
              }
            }
          }

          .border-left {
            border-left: solid 1px #707070;
            padding: 1rem;
            overflow: hidden;

            hr {
              width: 110%;
              position: relative;
              background-color: #707070;
              left: -20px;
            }
          }

          .physical-text {
            height: 10rem;
          }
        }

        .row-group {
          .input-group.inline-header {
            flex: 1;
            flex-direction: column;

            textarea {
              width: 100%;
            }
          }

          .input-group.inline-header-small {
            flex: 0.5;
            flex-direction: column;

            .no-border {
              border: none;
              border-bottom: solid 1px #707070;
              border-radius: 0 !important;
            }
          }
        }
      }

      .small-header {
        margin: 0 0 10px;
        padding: 0;
        font-weight: bold;
        font-size: 13px;
      }
      .max-width {
        max-width: 140px;
      }
      .checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .form-label {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
        }

        .selections {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;

          .form-check {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0;

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            input {
              width: 25px;
              height: 25px;
              float: unset;
              margin: 0;
            }
          }
        }
      }

      .row-group {
        display: flex;
        align-items: center;
        gap: 1rem;

        .input-group {
          display: flex;
          align-items: center;
          gap: 10px;

          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }
          p {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }

          input {
            border-radius: 7px !important;
            font-size: 13px;
            height: 33px;

            &::placeholder {
              font-weight: normal;
              font-size: 13px;
            }
          }
        }
        .max-width {
          max-width: 140px;
        }
        .small {
          flex: 1;
          flex-wrap: nowrap;

          input {
            flex: 0 0 7rem;
          }
        }
        .medium {
          flex: 1;
          flex-wrap: nowrap;

          input {
            flex: 0 0 12rem;
          }
        }

        .religion {
          flex: 1.2;
        }
      }

      .text-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
          font-weight: bold;
          font-size: 1.2rem;
        }

        p {
          font-weight: bold;
          margin: 0;
          padding: 0;
          font-size: 1rem;
          line-height: 1.4;
        }
      }

      .first-row {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        .input-group {
          width: 50%;
          max-width: 400px;
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 5px;

          input {
            flex: 1;
            width: 100%;
            font-size: 1rem;

            &::placeholder {
              font-weight: normal;
              font-size: 1rem;
            }
          }

          label {
            font-weight: bold;
          }
        }
      }

      .second-row {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: flex-end;

        .input-group,
        .left-input-group {
          width: 50%;
          max-width: 400px;
          display: flex;
          justify-content: space-between;
          text-align: center;
          gap: 10px;

          input {
            flex: 0 0 220px;
            width: 100%;
            font-size: 1rem;

            &::placeholder {
              font-weight: normal;
              font-size: 1rem;
            }
          }

          label {
            font-weight: bold;
            margin: 0;
            display: flex;
            align-items: center;
          }
        }

        .left-input-group {
          max-width: 500px;
          display: flex;
          flex-direction: column;
          gap: 7px;

          .group {
            display: flex;
            align-items: center;
          }

          p {
            font-weight: bold;
            margin: 0;
            line-height: 1;
            display: flex;
            align-items: center;
            font-size: 1rem;
          }
        }
      }

      .third-row {
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          margin: 0;
          font-weight: bold;
        }
      }

      .bordered-container {
        border: solid 1px #707070;
        border-radius: 5px;
        padding: 1rem;
        display: flex;

        .first-column,
        .second-column {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: flex-start;
          align-items: center;

          h5 {
            font-size: 1rem;
            font-weight: bold;

            span {
              display: block;
              font-style: italic;
              font-size: 12px;
              padding: 4px 0 0 4px;
            }
          }

          .input-group {
            display: flex;
            align-items: center;
            gap: 10px;

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            input {
              border-radius: 7px !important;
              font-size: 13px;
              height: 33px;

              &::placeholder {
                font-weight: normal;
                font-size: 13px;
              }
            }
          }

          .checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .form-label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            .selections {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 20px;

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                label {
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 13px;
                }

                input {
                  width: 25px;
                  height: 25px;
                  float: unset;
                  margin: 0;
                }
              }
            }
          }

          .one-row {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;

            .input-group {
              flex-direction: column;
              align-items: self-start;
              gap: 5px;

              input {
                width: 100%;
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;
              }
            }

            .sex {
              flex: 0.5;

              .sex-selection {
                display: flex;
                gap: 10px;

                .form-check {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  padding: 0;

                  label {
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    font-size: 13px;
                  }

                  input {
                    width: 25px;
                    height: 25px;
                    float: unset;
                    margin: 0;
                  }
                }
              }
            }

            .age {
              flex: 0 0 10rem;
            }

            .citizenship {
              flex: 0 0 10rem;
            }

            .occupation {
              flex: 2;
            }
          }

          &.large-width {
            flex: 2;
          }
        }

        .third-column {
          flex: 3;
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: flex-start;
          align-items: center;

          h5 {
            font-size: 1rem;
            font-weight: bold;
          }

          .row-cont {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;

            .input-group {
              display: flex;
              align-items: center;
              gap: 10px;

              label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }

              input {
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;
                font-weight: bold;

                &::placeholder {
                  font-weight: normal;
                  font-size: 13px;
                }
              }
            }

            .checkbox-cont {
              flex: 0 0 2rem;

              input {
                width: 25px;
                height: 25px;
                float: unset;
                margin: 0;
              }
            }

            .date-time,
            .type {
              display: flex;
              align-items: center;
              gap: 10px;

              label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }

              input {
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;
                font-weight: bold;

                &::placeholder {
                  font-weight: normal;
                  font-size: 13px;
                }
              }
            }

            .date-time {
              flex: 1.5;

              .form-control {
                flex: 0 0 12rem;
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;
                font-weight: bold;

                &::placeholder {
                  font-weight: normal;
                  font-size: 13px;
                }
              }
            }

            .type {
              flex: 1;

              input {
                flex: 0 0 10rem;
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;
                font-weight: bold;

                &::placeholder {
                  font-weight: normal;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      .bordered-container.information {
        .left-cont {
          flex: 0 0 20%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: flex-start;

          h5 {
            font-size: 1rem;
            font-weight: bold;

            span {
              display: block;
              font-style: italic;
              font-size: 12px;
              padding: 4px 0 0 4px;
            }
          }

          .input-group {
            display: flex;
            align-items: center;
            gap: 10px;

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            input {
              border-radius: 7px !important;
              font-size: 13px;
              height: 33px;

              &::placeholder {
                font-weight: normal;
                font-size: 13px;
              }
            }
          }
        }

        .right-cont {
          padding-left: 2rem;
          flex: 0 0 80%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: flex-end;

          h5 {
            font-size: 1rem;
            font-weight: bold;

            span {
              display: block;
              font-style: italic;
              font-size: 12px;
              padding: 4px 0 0 4px;
            }
          }

          .row-group {
            display: flex;
            align-items: center;

            .input-group {
              display: flex;
              align-items: center;
              gap: 10px;

              label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }

              input {
                border-radius: 7px !important;
                font-size: 13px;
                height: 33px;

                &::placeholder {
                  font-weight: normal;
                  font-size: 13px;
                }
              }
            }

            .small {
              flex: 1;

              input {
                flex: 0 0 7rem;
              }
            }

            .religion {
              flex: 1.2;
            }
          }
        }
      }

      .emergency-header {
        width: 100%;
        text-align: center;
        font-weight: bold;
      }

      .bordered-container.emergency-record-room {
        .info {
          flex: 1;
          border-right: solid 1px #000;
          justify-content: flex-end;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 0 1rem;

          h5 {
            font-size: 1rem;
            font-weight: bold;

            span {
              display: block;
              font-style: italic;
              font-size: 12px;
              padding: 4px 0 0 4px;
            }
          }

          .input-group {
            display: flex;
            align-items: center;
            gap: 10px;

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            input {
              border-radius: 7px !important;
              font-size: 13px;
              height: 33px;

              &::placeholder {
                font-weight: normal;
                font-size: 13px;
              }
            }
          }

          .checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .form-label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            .selections {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 20px;

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                label {
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 13px;
                }

                input {
                  width: 25px;
                  height: 25px;
                  float: unset;
                  margin: 0;
                }
              }
            }
          }
        }

        .info.one {
          .input-group {
            input {
              flex: 0 0 10rem;
            }
          }
        }

        .info.four {
          border-right: none;
        }
      }

      .bordered-container.condition-on-arrival {
        .info {
          flex: 1;
          border-right: solid 1px #000;
          justify-content: flex-start;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 0 1rem;

          h5 {
            font-size: 1rem;
            font-weight: bold;

            span {
              display: block;
              font-style: italic;
              font-size: 12px;
              padding: 4px 0 0 4px;
            }
          }

          .input-group {
            display: flex;
            align-items: center;
            gap: 10px;

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            input {
              border-radius: 7px !important;
              font-size: 13px;
              height: 33px;

              &::placeholder {
                font-weight: normal;
                font-size: 13px;
              }
            }
          }

          .checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .form-label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            .selections {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 20px;

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                label {
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 13px;
                }

                input {
                  width: 25px;
                  height: 25px;
                  float: unset;
                  margin: 0;
                }
              }
            }
          }
        }

        .info.two {
          .input-group {
            input {
              flex: 0 0 10rem;
            }
          }
        }

        .info.last {
          border-right: none;
        }
      }

      .date-group {
        display: flex;
        align-items: center;
        gap: 10px;

        label {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
        }

        input {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;

          &::placeholder {
            font-weight: normal;
            font-size: 13px;
          }
        }
      }

      .brief-history {
        h5 {
          margin: 0 0 1rem;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
          border-bottom: solid 1px #707070;
        }

        .input-group {
          display: flex;
          align-items: center;
          gap: 10px;

          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }

          input {
            border-radius: 7px !important;
            font-size: 13px;
            height: 33px;

            &::placeholder {
              font-weight: normal;
              font-size: 13px;
            }
          }

          .checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .form-label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            .selections {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 20px;

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                label {
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 13px;
                }

                input {
                  width: 25px;
                  height: 25px;
                  float: unset;
                  flex: 0 0 25px;
                  margin: 0;
                }
              }
            }
          }
        }

        .group-cont {
          display: flex;
          justify-content: space-around;
          gap: 2rem;
        }
      }

      .signatures {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input-group {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 400px;

          input {
            border: none;
            border-bottom: solid 2px #000;
            border-radius: 0;
            padding-bottom: 10px;
          }

          label {
            padding-top: 7px;
            font-weight: bold;
            font-size: 1rem;
          }
        }
      }

      .name {
        margin: 0 0 1rem 0;
        padding: 0 0 1rem 0;
        border-bottom: solid 1px #c8c8c8;

        .input-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;

          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }

          input {
            border-radius: 7px !important;
            font-size: 13px;
            height: 33px;
            width: 100%;

            &::placeholder {
              font-weight: normal;
              font-size: 13px;
            }
          }
        }
      }

      .gap-10 {
        gap: 20px;
      }

      p {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 2.5;
      }

      input {
        display: inline-block;
        width: unset;
        font-size: 1.2rem;
        font-weight: bold;
      }

      input.age {
        width: 4rem;
      }

      input.non-editable {
        background: none;
        padding: 0;
        border: none;
      }

      .purpose-line-bottom,
      .request-line-bottom {
        display: inline-block;
        width: unset;
        position: relative;

        &::after {
          position: absolute;
          left: 0;
          bottom: -15px;
          right: 0;
          text-align: center;
          font-size: 10px;
          line-height: 1;
          padding-top: 5px;
        }
      }

      .purpose-line-bottom {
        &::after {
          content: 'Purpose';
        }

        &.non-editable {
          &::after {
            display: none;
          }
        }
      }

      .request-line-bottom {
        &::after {
          content: '( Name of Person Requesting )';
        }

        &.non-editable {
          &::after {
            display: none;
          }
        }
      }

      .signature {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;

        .attending-physician {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 400px;

          input {
            border: none;
            border-bottom: solid 2px #000;
            border-radius: 0;
            padding-bottom: 10px;
          }

          label {
            font-weight: bold;
            font-size: 1.2rem;
          }
        }

        .license-number {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 400px;

          label {
            font-weight: bold;
            font-size: 1.2rem;
            margin: 0;
            display: flex;
            align-items: center;
          }

          input {
            &::placeholder {
              font-weight: normal;
            }
          }
        }
      }

      p.seal {
        border-bottom: solid 1px grey;
      }

      .letter-cont {
        h2 {
          margin: 0;
          padding: 0;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 2.5;
        }
      }
    }

    .form-buttons {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;

      Button.btn {
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        border: none;

        &.print {
          background-color: #ff6e6e;
        }

        &.edit,
        &.save {
          background-color: #01b425;
        }

        &.cancel {
          background-color: #b4b4b4;
        }
      }
    }
  }
}

.form-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .form-modal-header {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .form {
        padding: 2em;

        .header-search {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 0;
            font-weight: bold;
            font-size: 1.2rem;
          }

          .basic-search {
            .bg-grey.form-control {
              border-top-right-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
            }

            .btn.btn-search {
              margin-left: 1em !important;
              border-radius: 10px !important;
            }

            .bg-white {
              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .form-table-modal {
          .tableUI {
            .table {
              thead {
                tr {
                  th:last-child {
                    text-align: center;
                  }
                }
              }
              tbody {
                tr {
                  td {
                    span.admitted {
                      background-color: #01b425;
                      color: #fff;
                      font-size: 14px;
                      border-radius: 5px;
                      padding: 5px;
                    }
                  }

                  td.table-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    Button.btn {
                      font-size: 14px;
                      border-radius: 5px;
                      padding: 5px;
                      text-transform: capitalize;
                      border: none;
                    }

                    Button.btn.view {
                      background-color: #00a8ff;
                    }

                    Button.btn.download {
                      background-color: #01b425;
                    }
                  }
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          Button.btn {
            background-color: #ff6e6e;
            font-size: 14px;
            border-radius: 5px;
            padding: 5px;
            text-transform: capitalize;
            border: none;
          }
        }
      }
    }
  }
}
