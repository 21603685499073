.layout {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;

  & > .layout__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #f4f6fd;
  }

  @media (max-width: 991px) {
    position: relative;
  }
}
