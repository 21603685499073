.headerUI {
  margin-bottom: 2em;

  & > h4 {
    color: #00a8ff;
    font-weight: bold;
  }

  & > p {
    color: #00a8ff;
    font-size: 14px;
  }
}
