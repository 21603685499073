.edit-room-cont {
  h3 {
    margin: auto 0;
    writing-mode: horizontal-tb;
    text-orientation: upright;
  }
  .advanced-search {
    .accordion-item {
      border: none;

      .accordion-header {
        .accordion-button {
          font-weight: bold;
          padding: 1rem 1.5rem;
          color: #00a8ff;
          background-color: #fff;
          box-shadow: none;
          border-bottom: 2px solid #000;

          &.collapsed {
            color: #000;
            border-bottom: none;
          }

          &::after {
            margin-right: auto;
            position: absolute;
            left: 0;
          }
        }
      }

      .accordion-body {
        h6 {
          font-weight: bold;
          font-size: 1rem;
        }

        label {
            font-weight: bold;
        }

        .buttonWrapper {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
        }
      }
    }
  }
}
