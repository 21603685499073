.live-birth-form {
  .black-border-container {
    border: solid 4px #000;
    padding: 0;

    .black-row.last-row {
      border-bottom: none;
    }

    .black-row {
      border-bottom: solid 4px #000;

      .block-container {
        padding: 1rem;

        h4 {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
        }

        .certify {
          font-weight: normal;
          font-size: 13px;
          text-indent: 3rem;
        }

        .signature {
          position: relative;
          max-width: 340px;
          margin-left: auto;

          p.over-printed-name {
            position: absolute;
            font-size: 13px !important;
            font-weight: bold;
            bottom: -2rem;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto;
          }

          input {
            font-size: 13px !important;
            width: 100% !important;

            &::placeholder {
              font-weight: normal;
            }
          }
        }

        input.non-editable,
        input.editable {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;

          &::placeholder {
            font-weight: normal;
          }
        }
      }

      .block-container.bordered-top-right {
        border-top: solid 4px #000;
        border-right: solid 4px #000;
      }

      .main-content {
        width: 100%;
      }

      .right-border {
        border-right: solid 4px #000;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        padding: 1rem;

        .center-header {
          text-align: center;

          p {
            margin: 0;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.6;
          }

          h2 {
            font-weight: bold;
            font-size: 1.5rem;
            margin: 5px 0 0;
          }
        }

        .left-header,
        .right-header {
          margin: 0;
          line-height: 1.4;
          align-self: flex-start;
          font-size: 10px;

          span {
            display: block;
          }
        }
      }

      .inline-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .input-group {
          align-items: center;
        }
        .date-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .year-container,
          .month-container,
          .day-container {
            width: 100%;
            text-align: center;

            padding: 0 0.5rem;
          }
          .dropdown-year,
          .dropdown-month,
          .dropdown-day {
            text-transform: none;
            border-radius: 7px !important;
            font-size: 13px;
            height: 33px;
            width: 100%;
            text-align: center;
            border: 1px solid #c8c8c8;
          }
        }
      }

      .selections {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;

        .form-check {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 0;

          input {
            width: 25px;
            height: 25px;
            float: unset;
            margin: 0;
            border-radius: 7px;
          }

          label {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 13px;
          }
        }
      }

      .selections.inline {
        flex-direction: row;
      }

      .input-group {
        flex-direction: column;
        display: flex;
        gap: 10px;

        label {
          margin: 0;
          padding: 0;
          font-weight: bold;
          font-size: 13px;
        }

        input {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;

          &::placeholder {
            font-weight: normal;
          }
        }

        textarea {
          font-size: 13px;
          font-weight: bold;
          border-radius: 7px !important;
        }

        input,
        textarea {
          width: 100%;
          border-radius: 7px;

          &::placeholder {
            font-size: 13px;
            font-weight: normal;
          }
        }

        .selections {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;

          .form-check {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 0;

            input {
              width: 25px;
              height: 25px;
              float: unset;
              margin: 0;
            }

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }
          }
        }

        .selections.inline-selection {
          flex-direction: row;
        }

        .gender {
          border-radius: 7px !important;
          font-size: 13px;
          height: 33px;
          width: 100%;
        }
      }

      .inline-label {
        flex-direction: row !important;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
      }

      .block-with-sidebar {
        display: flex;

        .side-header {
          flex: 0 0 2rem;
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            font-size: 0.9rem;
            font-weight: bold;
            writing-mode: vertical-rl;
            text-orientation: upright;
            margin: 0;
          }
        }
      }
    }

    .black-row.remarks-row {
      height: 10rem;
      border-bottom: none;

      h2 {
        font-weight: bold;
        font-size: 1.3rem;
      }
    }
  }
}
