@media print {
  @page {
    margin: 1.5cm 1cm;
  } 
  .print_body {
    header {
      display: flex;
      align-items: center;
      flex-direction: column;

      .header_logo {
        margin: 15px auto;
        img {
            text-align: center;
            width: 170px;
            height: auto;
        }
      }

      .header_title {
        font-weight: bold;
        font-size: 16px;
        color: black !important;
      }

      .header_subtitle {
        margin-top: 5px;
        font-weight: bold;
        font-size: 14px;
        color: black !important;
      }
    }
    .first-row {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;

      .input-group {
        width: 50%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 5px;

        input {
          flex: 1;
          width: 100%;
          font-size: 1rem;

          &::placeholder {
            font-weight: normal;
            font-size: 1rem;
          }
        }

        label {
          font-weight: bold;
        }
      }
    }

    .second-row {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: flex-end;

      .input-group,
      .left-input-group {
        width: 50%;
        max-width: 400px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        gap: 10px;

        input {
          flex: 0 0 220px;
          width: 100%;
          font-size: 1rem;

          &::placeholder {
            font-weight: normal;
            font-size: 1rem;
          }
        }

        label {
          font-weight: bold;
          margin: 0;
          display: flex;
          align-items: center;
        }
      }

      .left-input-group {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 7px;

        .group {
          display: flex;
          align-items: center;
        }

        p {
          font-weight: bold;
          margin: 0;
          line-height: 1;
          display: flex;
          align-items: center;
          font-size: 1rem;
        }
      }
    }

    .third-row {
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        margin: 0;
        font-weight: bold;
      }
    }
    .blue-header-container {
      border: solid 1px #707070;
      border-radius: 5px;

      h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00a8ff;
        padding: 4px 0;
        color: #fff;
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0;
      }
      .box-cont {
        border-top: solid 1px #707070;
        border-bottom: solid 1px #707070;
        border-radius: 0;
        padding: 1rem;
        .text-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
  
          h4 {
            font-weight: bold;
            font-size: 1.2rem;
          }
  
          p {
            font-weight: bold;
            margin: 0;
            padding: 0;
            font-size: 1rem;
            line-height: 1.4;
          }
        }

        .info {
          flex: 1;
          justify-content: flex-start;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 0;

          h6 {
            margin: 0;
            padding: 0;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
          }

          h5 {
            font-size: 1rem;
            font-weight: bold;

            span {
              display: block;
              font-style: italic;
              font-size: 12px;
              padding: 4px 0 0 4px;
            }
          }
          .input-group-col {
            display: flex;
            flex-direction: column;
          }
          .input-group {
            display: flex;
            flex-direction: row;
            // gap: 10px;

            label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            p {
              font-size: 13px;
              height: 33px;
            }

            .form-check {
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 0;

              label {
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 13px;
              }

              input {
                width: 25px;
                height: 25px;
                float: unset;
                margin: 0;
              }
            }
          }

          .checkbox {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;

            .form-label {
              margin: 0;
              padding: 0;
              font-weight: bold;
              font-size: 13px;
            }

            .selections {
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 100%;

              input {
                min-width: 30px !important;
                min-height: 30px !important;
              } 

              .form-check {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                label {
                  margin: 0;
                  padding: 0;
                  font-weight: bold;
                  font-size: 13px;
                } 

                input {
                  width: 25px;
                  height: 25px;
                  margin: 0;
                }
              }

            }
          }

          .two-sides {
            display: flex;
            flex-direction: row;
            width: 100%;

            justify-content: space-between;

            hr {
              width: 1px;
              height: 100%;
              background-color: #707070;
              margin-top: auto;
            }

            .checkbox {
            .selections {
              flex: 0 0 auto;
            }}
          }
        }

        .border-left {
          border-left: solid 1px #707070;
          padding: 1rem;
          overflow: hidden;

          hr {
            width: 110%;
            position: relative;
            background-color: #707070;
            left: -20px;
          }
        }

        .physical-text {
          height: 10rem;
        }
      }

      .row-group {
        display: flex; 
        flex-direction: row;
        flex-wrap: wrap;  
        gap: 10px;

        .input-group {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          flex: 1;

          label {
            padding: 0;
            margin: 0;
            white-space: nowrap;
          }

          p {
            margin: 0;
            padding: 0;
            word-break: break-all;
          }
        }

        .small {
          flex: 1;
          flex-wrap: nowrap;

          p {
            flex: 0 0 7rem;
          }
        }
        .medium {
          flex: 1;
          flex-wrap: nowrap;

          p {
            flex: 0 0 12rem;
          }
        }

        .religion {
          flex: 1.2;
        }
          
        .input-group.inline-header {
          flex: 1;
          flex-direction: column;
          text-align: center;
          // textarea {
          //   width: 100%;
          // }
        }

        .input-group.inline-header-small {
          flex: 0.5;
          flex-direction: column;
          .no-border {
            border: none;
            border-bottom: solid 1px #707070;
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}